import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFormData } from '../store/store';
import Naptar from '../Naptar';
import './foglalas.css';
import { useNavigate } from "react-router-dom";

export function Foglalas() {
    const url = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formData = useSelector(state => state.booking.formData);
    const startDate = useSelector(state => state.booking.startDate);
    const endDate = useSelector(state => state.booking.endDate);

    function handleChange(event) {
        const { name, value } = event.target;
        dispatch(setFormData({ [name]: value }));
    }

    async function postData(url = '', data = {}) {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response.json();
    }

    function isDate(value) {
        const date = new Date(value);
        return !isNaN(date.getTime());
    }


    function Tovabb(e){
        e.preventDefault();
        if (isDate(startDate) && isDate(endDate) && formData.guests > 0 && formData.guests < 5 && startDate <= endDate) {
            postData(url+'/api/date/check', {
                erkezes: startDate,
                tavozas: endDate,
                vendegszam: formData.guests
            })
                .then(data => {
                    console.log('Sikeres válasz:', data);
                    if (data.foglalhato) {
                        navigate("/foglalas_pay");
                    }
                    else{
                        alert('Sajnos a foglalalás nem sikerült!:(');
                    }
                })
                .catch(error => {
                    console.error('Hiba történt:', error);
                });
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        console.log("Form adatok: ", formData);
        const data = {
            email: formData.email,
            guests: formData.guests,
            phone: formData.phone,
            name: formData.name,
            tol: startDate,
            ig: endDate,
            pet: formData.pet,
            pettype: formData.pettype,
            pet_body_type: formData.pet_body_type
        };
        
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    return (
        <div className='cont'>
        <div className="foglalas-container">
            <form id="bookingForm" onSubmit={Tovabb}>
                <Naptar />
                <div className="form-group">
                    <label htmlFor="name">Név:</label>
                    <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email cím:</label>
                    <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Telefonszám:</label>
                    <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="guests">Vendégek száma:</label>
                        <select id="guests" name="guests" value={formData.guests} onChange={handleChange} required>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                        </select>
                </div>
                <div className="form-group">
                    <label htmlFor="pet">Kisállat:</label>
                    <select id="pet" name="pet" value={formData.pet} onChange={handleChange} required>
                        <option value="nincs">Nincs</option>
                        <option value="van">Van</option>
                    </select>
                </div>
                {formData.pet =="van"?<div className='petgroup'>
                <div className="form-group">
                    <label htmlFor="pettype"></label>
                    <select id="pettype" name="pettype" value={formData.pettype} onChange={handleChange} required>
                        <option value="kutya">Kutya</option>
                        <option value="macska">Macska</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="pet_body_type"></label>
                    <select id="pet_body_type" name="pet_body_type" value={formData.pet_body_type} onChange={handleChange} required>
                        <option value="kistestu">Kistestű</option>
                        <option value="nagytestu">Nagytestű</option>
                    </select>
                </div>
                </div>:""}
                <button type="submit">Tovább</button>
            </form>
        </div>
        </div>
    );
}
